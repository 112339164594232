// IMPORTS
@import '../../App';



// HOME STYLES
.Home {
    --status_gray: #{$gray-200};
    --status_yellow: #{$yellow};
    --status_orange: #{$orange};
    --status_red: #{$red};
    --status_green: #{$green};
    --status_blue: #{$cyan};
    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    row-gap: 40px;
    max-width: 1000px;
}
.Home-form {
    width: auto;
    max-width: 500px;
    height: fit-content;
    padding: 15px;
    border: 2px solid $gray-500;
    border-radius: 10px;

    & .Home-form-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        row-gap: 15px;
    }
    & .Home-form-options {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
        column-gap: 20px;

        & > div {
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 65px;
            background-color: $yellow;
            border-radius: $border-radius;
            cursor: pointer;

            &:hover {
                background-color: $cyan;
            }
            & h1 {
                font-family: $font-primary;
                font-size: 18px;
                font-weight: 700;
                color: $black;
                user-select: none;
            }
        }
    }
    & .Home-form-buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: center;
        column-gap: 15px;
        margin-top: 5px;
    }
    & h1 {
        font-family: $font-primary;
        font-size: 22px;
        font-weight: 500;
        color: $gray-200;
        user-select: none;
    }
    & h2 {
        font-family: $font-primary;
        font-size: 15px;
        font-weight: 300;
        color: $gray-200;
        user-select: none;

        & > span {
            color: $cyan;
        }
    }
    & h3 {
        font-family: $font-monospace;
        font-size: 13px;
        font-weight: 300;
        color: $gray-300;
        user-select: none;

        & > span {
            color: $white;
            font-weight: 600;
        }
    }
    & p {
        font-family: $font-secondary;
        font-size: 14px;
        font-weight: 300;
        color: $white;
        user-select: none;
    }
}
.Home-requests {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    row-gap: 15px;

    & .Home-requests-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: stretch;
        justify-content: center;
        row-gap: 20px;
        column-gap: 20px;
        
        & .Home-requests-item {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            row-gap: 6px;
            width: 250px;
            min-height: fit-content;
            padding: 12px 5px 12px 5px;
            border-radius: 10px;

            & h1 {
                font-family: $font-primary;
                font-size: 22px;
                font-weight: 900;
                color: $black;
                user-select: none;
            }
            & h2 {
                font-family: $font-secondary;
                font-size: 16px;
                font-weight: 700;
                color: $black;
                user-select: none;
            }
            & p {
                font-family: $font-primary;
                font-size: 14px;
                font-weight: 600;
                color: $black;
                user-select: none;
            }
            & small {
                font-family: $font-monospace;
                font-size: 12px;
                font-weight: 500;
                color: $black;
                letter-spacing: -0.3px;
                user-select: none;
            }
        }
        & h5 {
            font-family: $font-secondary;
            font-size: 15px;
            font-weight: 500;
            color: $yellow;
            user-select: none;
        }
        & h6 {
            font-family: $font-secondary;
            font-size: 15px;
            font-weight: 500;
            color: $red;
            user-select: none;
        }
    }
    & h1 {
        font-family: $font-primary;
        font-size: 22px;
        font-weight: 500;
        color: $white;
        user-select: none;
    }
}