// IMPORTS
@import "fonts/fonts";



// VARIABLES
// Color Palette
$white: #ffffff;
$gray-100: #eeeeee;
$gray-200: #E3E4E4;
$gray-300: #BABBBC;
$gray-400: #8C8E90;
$gray-500: #5E6063;
$gray-600: #3C3E41;
$gray-700: #191C20;
$gray-800: #16191C;
$gray-900: #0E1113;
$black: #08090B;
$blue: #0D4CA7;
$indigo: #7234e7;
$purple: #8F5FE8;
$red: #ff5035;
$orange: #FF9900;
$yellow: #FFCD2C;
$green: #2EE06D;
$teal: #7FD9D5;
$cyan: #2F91E7;

// Fonts
$font-primary: "OneHiveSystems-Roboto-Condensed", "Helvetica", "Arial", "sans-serif";
$font-secondary: "OneHiveSystems-Roboto", "Helvetica", "Arial", "sans-serif";
$font-monospace: "OneHiveSystems-Monospace", "Courier New", monospace;

// Sizes
$border-radius: 4px;

// Misc
$breakpoint: 768px; //width of screen under which to start using mobile styling
$transition-speed: 0.35s;



// OVERRIDES
* {
  margin: 0;
  padding: 0;
  letter-spacing: 0.35px;
  transition: $transition-speed;
}
body {
  min-width: 300px !important;
  background: radial-gradient(circle, rgba(43,50,60,1) 0%, rgba(25,28,32,1) 100%);
  padding-top: 25px;
  padding-bottom: 25px;
}



// APP STYLES
.App {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.App-header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  & h1 {
    font-family: $font-primary;
    font-size: 30px;
    font-weight: 800;
    color: $white;
    text-transform: uppercase;
    user-select: none;
  }
}
.App-banner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  row-gap: 5px;
  width: auto;
  max-width: 500px;
  background-color: rgba(255, 153, 0, 0.65);
  border: 2px solid $orange;
  border-radius: $border-radius;
  margin-top: 25px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 8px;

  & p {
    font-family: $font-primary;
    font-size: 15px;
    font-weight: 400;
    color: $black;
    user-select: none;
  }
  & a {
    font-family: $font-primary;
    font-size: 20px;
    font-weight: 800;
    color: $blue;
    text-decoration: underline;
  }
}



// NAVIGATION
nav {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  row-gap: 10px;
  margin-bottom: 40px;
}



// BUTTON
.global-btn {
  width: fit-content;
  height: fit-content;
  padding: 5px 10px;
  background-color: $yellow;
  border: unset;
  border-radius: $border-radius;
  outline: none;
  font-family: $font-secondary;
  font-size: 14px;
  color: $black;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: $cyan;
  }
  &:disabled {
    background-color: $gray-400 !important;
    cursor: not-allowed;
  }
}
.global-btn-success {
  @extend .global-btn;

  background-color: $green;
}
.global-btn-error {
  @extend .global-btn;

  background-color: $red;
}



// INPUT
.global-input {
  width: auto;
  min-width: 20px;
  height: fit-content;
  margin: 0;
  padding: 4px 8px;
  background-color: unset;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $gray-200;
  outline: none;
  font-family: $font-primary;
  font-size: 15px;
  font-weight: bold;
  color: $white;
  text-align: center;

  &::placeholder {
      font-family: $font-primary;
      font-size: 14px;
      font-weight: lighter;
      color: $gray-300;
  }
  &:focus {
      border: 1px solid $gray-200;
      border-radius: $border-radius;
  }
}
.global-input option {
  color: $black;
}
.global-input[type="checkbox"] {
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.global-input[type="radio"] {
  width: 17px;
  height: 17px;
  cursor: pointer;
}



// SCROLLBAR
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: $gray-400;
}
::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
}