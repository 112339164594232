// IMPORTS
@import '../../App';



// LOGIN STYLES
.Login {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    row-gap: 15px;
    
    & input {
        min-width: 250px;
    }
    & p {
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 500;
        color: $green;
        user-select: none;
    }
}