@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Regular.woff") format('woff'),
        url("../fonts/RobotoCondensed-Regular.woff2") format('woff2');
    font-weight: normal;
}
@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Bold.woff") format('woff'),
        url("../fonts/RobotoCondensed-Bold.woff2") format('woff2');
    font-weight: bold;
}
@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Light.woff") format('woff'),
        url("../fonts/RobotoCondensed-Light.woff2") format('woff2');
    font-weight: 100;
}
@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Light.woff") format('woff'),
        url("../fonts/RobotoCondensed-Light.woff2") format('woff2');
    font-weight: 200;
}
@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Light.woff") format('woff'),
        url("../fonts/RobotoCondensed-Light.woff2") format('woff2');
    font-weight: 300;
}
@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Regular.woff") format('woff'),
        url("../fonts/RobotoCondensed-Regular.woff2") format('woff2');
    font-weight: 400;
}
@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Regular.woff") format('woff'),
        url("../fonts/RobotoCondensed-Regular.woff2") format('woff2');
    font-weight: 500;
}
@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Regular.woff") format('woff'),
        url("../fonts/RobotoCondensed-Regular.woff2") format('woff2');
    font-weight: 600;
}
@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Bold.woff") format('woff'),
        url("../fonts/RobotoCondensed-Bold.woff2") format('woff2');
    font-weight: 700;
}
@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Bold.woff") format('woff'),
        url("../fonts/RobotoCondensed-Bold.woff2") format('woff2');
    font-weight: 800;
}
@font-face {
    font-family: "OneHiveSystems-Roboto-Condensed";
    src: url("../fonts/RobotoCondensed-Bold.woff") format('woff'),
        url("../fonts/RobotoCondensed-Bold.woff2") format('woff2');
    font-weight: 900;
}
@font-face {
    font-family: "OneHiveSystems-Monospace";
    src: url("../fonts/DroidSansMono.woff") format('woff'),
        url("../fonts/DroidSansMono.woff2") format('woff2');
    font-weight: normal;
}