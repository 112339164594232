// IMPORTS
@import '../../App';



// ADMIN STYLES
main {
    width: 100%;
    max-width: 1000px;
}
.Admin {
    --status_gray: #{$gray-200};
    --status_yellow: #{$yellow};
    --status_orange: #{$orange};
    --status_red: #{$red};
    --status_green: #{$green};
    --status_blue: #{$cyan};

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    row-gap: 25px;
}
.Admin-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    column-gap: 12px;
    border: 2px solid $gray-500;
    border-radius: 10px;

    & > div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        column-gap: 4px;
        padding: 8px;
        cursor: pointer;

        &:hover {
            background-color: $blue;
        }
        & img {
            width: 26px;
            height: auto;
        }
        & h1 {
            font-family: $font-primary;
            font-size: 20px;
            font-weight: 500;
            color: $white;
            user-select: none;
        }
    }
}
.Admin-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: 15px;
    width: 90%;
    padding: 15px;
    background-color: $gray-700;

    & .Admin-content-header {

        & h1 {
            font-family: $font-primary;
            font-size: 22px;
            font-weight: 500;
            color: $white;
            user-select: none;
        }
    }
}
.Admin-requests {

    & .Admin-requests-content {
        display: grid;
        grid-template-columns: 250px auto;
        column-gap: 30px;

        & > h1 {
            font-family: $font-primary;
            font-size: 17px;
            font-weight: 500;
            color: $red;
        }
    }
    & .Admin-requests-list {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: flex-start;

        & > h1 {
            font-family: $font-primary;
            font-size: 17px;
            font-weight: 500;
            color: $red;
        }
    }
    & .Admin-requests-listitem {
        display: grid;
        grid-template-columns: 18px auto;
        justify-content: start;
        align-items: center;
        width: 100%;
        column-gap: 8px;
        padding: 4px;
        cursor: pointer;

        &:hover {
            background-color: $blue;
        }
        & > div {
            width: 18px;
            height: 18px;
            border-radius: 9px;
        }
        & h1 {
            font-family: $font-primary;
            font-size: 18px;
            font-weight: 500;
            color: $white;
            user-select: none;
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    & .Admin-requests-details {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 8px;

        & > div {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;
            justify-content: flex-start;
            column-gap: 10px;
            width: 100%;

            & h1 {
                font-family: $font-primary;
                font-size: 15px;
                font-weight: 300;
                color: $gray-300;
                user-select: none;
            }
            & p {
                font-family: $font-primary;
                font-size: 17px;
                font-weight: 300;
                color: $white;
            }
            & input {
                width: 80%;
                text-align: left;
            }
        }
        & .Admin-requests-statusgroup {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: flex-start;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 5px;

            & > div {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                column-gap: 10px;

                & input {
                    width: unset;
                }
                & p {
                    user-select: none;
                }
            }
        }
    }
}